import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard, useMount, useUpdateEffect } from 'react-use';
import { useYaMetrica } from '~frontend/provider/yametrica';

import { toast } from 'react-toastify';

import { useConfig } from '~frontend/components/hooks';
import '../../scss/pages/_404.scss';

const NotFound: FunctionComponent = () => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const { reachGoal } = useYaMetrica();
  const getConfig = useConfig();

  useMount(() => {
    reachGoal('404');
  });

  useUpdateEffect(() => {
    toast.info(t('Copy'));
  }, [state]);

  const { t } = useTranslation();

  const onCopyToClipboard = () => {
    copyToClipboard('404PNF');
  };

  return (
    <>
      <Helmet>
        {getConfig?.siteOffline ? (
          <title>Maintence</title>
        ) : (
          <title>NotFound</title>
        )}
      </Helmet>
      <div className="page-404-wrap">
        <div className="page-404">
          {getConfig?.siteOffline ? (
            <div
              className="page-404-text"
              dangerouslySetInnerHTML={{
                __html: t('maintenance_error'),
              }}
            />
          ) : (
            <div
              className="page-404-text"
              dangerouslySetInnerHTML={{ __html: t('This is our secret page') }}
            />
          )}
          {getConfig?.siteOffline ? (
            <></>
          ) : (
            <div className="page-404-code">
              <div className="refer-input">
                <input type="text" value="404PNF" readOnly />
                <a href="#" onClick={onCopyToClipboard} className="link-copy">
                  <span className="icon icon-copy" />
                </a>
              </div>
              <div
                className="page-404-input-label"
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Enter this code while topping up the balance and get 5 to the payment amount',
                  ),
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NotFound;
